<template>
  <div class="pt-4 pt-md-3">
    <DashBoard v-if="$route.name === 'AccommodationsList' && loaded">
      <div
        v-for="accommodation of $store.state.accommodationsList"
        :key="accommodation.Id"
        class="flex-grow-1 m-3"
        :class="$store.state.app.listAppearance === 'List' ? 'w-100' : ''"
      >
        <Accommodation
          v-show="filter(accommodation)"
          :accommodation="accommodation"
        />
      </div>
    </DashBoard>

    <Loader v-else-if="!loaded" />

    <router-view v-else />
  </div>
</template>

<script setup>
import Accommodation from "@/components/summary/accommodation/Accommodation";

import Setup from "./AccommodationsListSetup";
const { filter, loaded } = Setup();
</script>

