import { onMounted, ref } from "vue";
import { useStore } from "vuex";

export default function Setup() {
  const store = useStore();

  const loaded = ref(false);

  const filter = (elem) => {
    // store.state.app.filterList.findIndex((element) => element.Id == accommodation.Id
    if (!store.state.app.search || elem.Address.FullAddress.toLowerCase().indexOf(store.state.app.search.toLowerCase()) > -1) {
      return true
    }
    else return false
  };

  onMounted(() => {
    store.dispatch("getAllAccommodations")
    .then(() => loaded.value = true)
  });

  return {
    filter,
    loaded
  }
}
